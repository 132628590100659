<template>
  <div class="btn-cyan">
    <router-link to="/sistemas-fazendarios">
      <mdicon name="FitToPage" class="flex justify-center text-white" size="90" />
        <h2 class=" text-white text-2xl text-center font-semibold mb-4">{{ title }}</h2>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
}
</script>
