<template>
  <div class="btn-yellow">
    <a :href="url">
      <mdicon name="CheckCircleOutline" class="flex justify-center text-white" size="90" />
      <h2 class=" text-white text-2xl text-center font-semibold mb-4">{{ title }}</h2>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'http://siaudi.duquedecaxias.rj.gov.br/siaudi2/site/login'
    }
  },
  props: {
    title: String
  },
}
</script>