<template>
  <div class="btn-yellow">
    <a :href="url" target="_blank">
      <mdicon name="Gavel" class="flex justify-center text-white" size="90" />
      <h2 class=" text-white text-2xl text-center font-semibold">{{ title }}</h2>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'https://drive.google.com/drive/folders/1gPs0rQIqIUnFb6la-3ogTw83ve1GD99C'
    }
  },
  props: {
    title: String
  },
}
</script>