<template>
  <div>
    <nav-bar-view />
    <header-view />
    <cards-view />
    <router-view></router-view>
  </div>
</template>

<script>
import CardsView from '@/components/CardsView.vue';
import NavBarView from '@/components/NavBarView.vue';
import HeaderView from '@/components/HeaderView.vue';

export default {
  components: { 
    CardsView,
    NavBarView,
    HeaderView,
  },
}
</script>