<template>
  <div class="btn-yellow">
    <router-link to="/leis-municipais">
      <mdicon name="BookOpenPageVariantOutline" class="flex justify-center text-white" size="90" />
      <h2 class=" text-white text-2xl text-center font-semibold mb-4">{{ title }}</h2>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
}
</script>