<template>
  <div class="btn-cyan">
    <a :href="url">
      <mdicon name="EmailMultipleOutline" class="flex justify-center text-white" size="90" />
      <h2 class=" text-white text-2xl text-center font-semibold mb-4">{{ title }}</h2>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&service=mail&hd=smeduquedecaxias.rj.gov.br&sacu=1&flowName=GlifWebSignIn&flowEntry=AddSession'
    }
  },
  props: {
    title: String
  },
}
</script>