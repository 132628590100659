<template>
  <div>
    <div class="bg-custom-blue">
      <nav class="container px-6 py-3 mx-auto md:flex md:justify-between md:items-center">
        <div class="flex items-center justify-between">
          <a :href="urlHome">
            <img src="../assets/logo-fnd-branco.png" class="h-14 w-30">
          </a>
          <!-- Mobile menu button -->
          <div @click="showMenu = !showMenu" class="flex md:hidden">
            <button type="button" class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400">
              <svg viewBox="0 0 24 24" class="w-8 h-8 fill-current">
                <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
                </path>
              </svg>
            </button>
          </div>
        </div>


        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul :class="showMenu ? 'flex' : 'hidden'" class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center
              md:space-x-10 md:mt-0">
          <li class="text-base font-bold text-white hover:text-custom-orange ">
            <a :href="urlHome">Home</a>
          </li>
          <li class="text-base font-bold text-white hover:text-custom-orange ">
            <a :href="urlTransparencia" target="_blank">Portal da Transparência</a>
          </li>
          <li class="text-base font-bold text-white hover:text-custom-orange ">
            <a :href="urlSiteOficial" target="_blank">PMDC - Site Oficial</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  data()
  {
    return {
      showMenu: false,
      urlHome: 'http://intranet.duquedecaxias.rj.gov.br',
      urlTransparencia: 'https://transparencia.duquedecaxias.rj.gov.br/',
      urlSiteOficial: 'https://duquedecaxias.rj.gov.br/',
    };
  },
};
</script>