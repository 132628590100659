import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/leis-municipais',
    name: 'leis-municipais',
    component: () => import('../views/LeisMunicipais.vue')
  },
  {
    path: '/documentos-institucionais',
    name: 'documentos-institucionais',
    component: () => import('../views/DocumentosInstitucionais.vue')
  },
  {
    path: '/resolucoes',
    name: 'resolucoes',
    component: () => import('../views/Resolucoes.vue')
  },
  {
    path: '/sistemas-fazendarios',
    name: 'sistemas-fazendarios',
    component: () => import('../views/SistemasFazendarios.vue')
  },
  {
    path: '/monitoramento',
    name: 'monitoramento',
    component: () => import('../views/Monitoramento.vue')
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
