<template>
  <nav>
    <router-view/>
  </nav>
</template>


<script>
import HomeView from './views/HomeView.vue';

export default {
  name: 'App',
  components: {
    HomeView,
  }
};

</script>
