<template>
  <div class="flex flex-wrap justify-center pt-16 pb-8 md:hover:ease-out">
    <card-protocolo title="protocolo" />
    <card-boletim title="boletim oficial" />
    <card-leis-municipais title="leis municipais" />
    <card-decretos-municipais title="decretos municipais" />
    <card-modelo-documentos title="modelo de documentos" />
    <card-resolucoes title="resoluções, intruções normativas, manuais e cartilhas" />
    <card-sistema-rh title="sistemas de r.h folha de pagamento" />
    <card-sistema-patrimonio title="sistema de patrimônio" />
    <card-ouvidoria title="acesso sistema de ouvidoria" />
    <card-ambiente-virtual title="ambiente virtual para estudos" />
    <card-sala-virtual title="sala virtual paulo freire" />
    <card-ieducar title="i-educar" />
    <card-sistema-iss title="sistema de iss, taxa e guias de iptu" />
    <card-sistema-contabil title="sistema contábil" />
    <card-sistema-auditoria title="sistema de auditoria" />
    <card-contracheque title="contracheque e comprovante de rendimentos" />
    <card-noticias title="notícias institucionais" />
    <card-email-institucional title="e-mail institucional" />
    <card-email-sme title="e-mail sme institucional" />
    <card-acesso-publico title="monitoramento acesso público" />
    <card-rede-ativos title="monitoramento de rede e ativos" />
  </div>
</template>

<script>

import CardProtocolo from './Cards/CardProtocolo.vue';
import CardBoletim from './Cards/CardBoletim.vue';
import CardLeisMunicipais from './Cards/CardLeisMunicipais.vue';
import CardAcessoPublico from './Cards/CardAcessoPublico.vue';
import CardAmbienteVirtual from './Cards/CardAmbienteVirtual.vue';
import CardContracheque from './Cards/CardContracheque.vue';
import CardDecretosMunicipais from './Cards/CardDecretosMunicipais.vue';
import CardEmailInstitucional from './Cards/CardEmailInstitucional.vue';
import CardEmailSme from './Cards/CardEmailSme.vue';
import CardIeducar from './Cards/CardIeducar.vue';
import CardModeloDocumentos from './Cards/CardModeloDocumentos.vue';
import CardNoticias from './Cards/CardNoticias.vue';
import CardOuvidoria from './Cards/CardOuvidoria.vue';
import CardRedeAtivos from './Cards/CardRedeAtivos.vue';
import CardResolucoes from './Cards/CardResolucoes.vue';
import CardSalaVirtual from './Cards/CardSalaVirtual.vue';
import CardSistemaAuditoria from './Cards/CardSistemaAuditoria.vue';
import CardSistemaContabil from './Cards/CardSistemaContabil.vue';
import CardSistemaIss from './Cards/CardSistemaIss.vue';
import CardSistemaPatrimonio from './Cards/CardSistemaPatrimonio.vue';
import CardSistemaRh from './Cards/CardSistemaRh.vue';


export default {
  components: {
    CardProtocolo,
    CardBoletim,
    CardLeisMunicipais,
    CardAcessoPublico,
    CardAmbienteVirtual,
    CardContracheque,
    CardDecretosMunicipais,
    CardEmailInstitucional,
    CardEmailSme,
    CardIeducar,
    CardModeloDocumentos,
    CardNoticias,
    CardOuvidoria,
    CardRedeAtivos,
    CardResolucoes,
    CardSalaVirtual,
    CardSistemaAuditoria,
    CardSistemaContabil,
    CardSistemaIss,
    CardSistemaPatrimonio,
    CardSistemaRh
  },
}
</script>
