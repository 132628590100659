<template>
  <div class="btn-cyan">
  <a :href="url">
    <mdicon name="FlagVariant" class="flex justify-center text-white" size="90" />
    <h2 class="text-white text-2xl text-center font-semibold mb-4">{{ title }}</h2>
  </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'http://protocolo.duquedecaxias.rj.gov.br:8003/stpd/user-login.action',
    }
  },
  props: {
    title: String
  },
}
</script>
