<template>
  <div class="btn-blue">
    <router-link to="/documentos-institucionais">
      <mdicon name="FileDocumentEditOutline" class="flex justify-center text-white" size="90" />
      <h2 class=" text-white text-2xl text-center font-semibold">{{ title }}</h2>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
}
</script>