<template>
  <div class="btn-blue">
    <a :href="url" target="_blank">
      <mdicon name="HeadCogOutline" class="flex justify-center text-white" size="90" />
      <h2 class=" text-white text-2xl text-center font-semibold mb-4">{{ title }}</h2>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'http://sistemas.smeduquedecaxias.rj.gov.br:70/'
    }
  },
  props: {
    title: String
  },
}
</script>